* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
}
@import "toastr";

/* css form Ant Design */
form[id="basic"] {
  margin-top: 20px;
}

a {
  text-decoration: none;
  color: unset;
}
