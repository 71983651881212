.container {
  max-width: var(--max-width);
  margin-inline: auto;
  padding: 20px 0;
}

.container h2 {
  font-weight: 700;
}

.container h3 {
  color: var(--color);
  font-weight: 600;
}

button.btn-signup {
  background-color: var(--color);
  padding: 9px 15px;
  border: none;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
}

button.btn-signup[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.box-signup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.box-signup div input {
  width: 100%;
}

.form-input {
  margin: 10px 0;
}

.form-input input {
  outline: none;
  padding: 5px 7px;
  border: none;
  border: 1px solid var(--color);
}

.message-error {
  color: red;
}

div.form-group input {
  outline: none;
  padding: 5px 7px;
  border: none;
  border: 1px solid var(--color);
}

.input_show_password:checked + .inputPassword label:nth-child(2) {
  display: none;
}

.inputPassword label:nth-child(3) {
  display: none;
}

.input_show_password:checked + .inputPassword label:nth-child(3) {
  display: block;
}

.message-error {
  color: red;
  display: block;
}

.box-login button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.inputPassword {
  position: relative;
  margin-bottom: 10px;
}

.inputPassword svg {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media only screen and (max-width: 390px) {
  .container :is(h2, h3) {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .box-signup {
    flex-direction: column;
    max-width: 360px;
    margin-inline: auto;
  }

  .btn-signup {
    margin-inline: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container :is(h2, h3) {
    text-align: center;
  }

  .box-signup {
    max-width: 720px;
    margin-inline: auto;
  }

  .btn-signup {
    margin-inline: auto;
  }
}
